import postService from '../services/post';

/**
 * @class PostPage
 * @summary A post page for a specific blog post.
 * @mixes $w.Element
 * @mixes $w.HiddenCollapsedMixin
 * @memberof $w
 * @viewername wysiwyg.viewer.components.tpapps.TPAWidget
 * @definitionId 211b5287-14e2-4690-bb71-525908938c81
 * @tagname postPage
*/

/**
 * @typedef {external:Object} $w.PostPage~BlogPost
 * @summary An object representing a blog post on a post page.
 * @property {external:String} _id Post ID.
 * @property {external:String} title Post title.
 * @property {external:String} plainContent Text of the post.
 * @property {external:Date} publishedDate Date the post was originally published.
 * @property {external:Number} viewCount Number of times the post was viewed.
 * @property {external:Number} likeCount Number of likes the post received.
 * @property {external:Number} commentCount Number of comments the post received.
 * @property {external:Date} lastPublishedDate Date the post was most recently published.
 * @property {external:Boolean} coverImageDisplayed Indicates whether the cover image is displayed in the post.
 * @property {external:Number} timeToRead Estimated time in minutes required to read the post.
 * @property {external:Boolean} pinned Indicates whether the post was pinned to the top of the blog feed.
 * @property {external:Boolean} featured Indicates whether the post is set as featured in the post settings. Featured posts appear in custom blog feeds.
 * @property {external:String[]} hashtags List of all hashtags in the post.
 * @property {external:String} coverImage The post's cover [image]($w.Image.html#src).
 * @property {external:String} postPageUrl Relative URL of the post page on your published site.
 * @property {external:String} excerpt A few lines of text that appear in the blog feed. Defined in <a href="https://support.wix.com/en/article/editing-excerpts-in-the-new-wix-blog" target="_blank">Post Settings</a> or default of first 160 characters of the post.
 * @snippet [PostPage-getPost.es6=Get the current blog post's information]
 * @see [`getPost()`](#getPost)
 */

const getSlug = wixCodeApi => {
  let path = wixCodeApi.location.path;
  if (path.length < 2) {
    return {};
  }
  let isId = false;
  if (path.length > 2 && path[path.length - 1] === 'edit') {
    path = path.slice(0, -1);
    isId = true;
  }
  const slug = path
    .slice(1)
    .map(decodeURIComponent)
    .join('/');
  return { isId, slug };
};

/**
 * @function getPost
 * @syntax
 * function getPost(): Promise<BlogPost>
 * @summary Gets the all the information associated with the current blog post.
 * @description
 *  The `getPost()` function returns a Promise that is resolved when all
 *  of the information about the post shown on the `PostPage` is
 *  retrieved.
 * @returns {Promise}
 * @fulfill {$w.PostPage~BlogPost} When the information associated with the current post has been retrieved.
 * @snippet [PostPage-getPost.es6=Get the current blog post's information]
 * @memberof $w.PostPage
 */

const postPageController = ({ appParams: { instance }, wixCodeApi }) => {
  return {
    pageReady: () => {},
    exports: () => ({
      getPost: () => {
        const { isId, slug } = getSlug(wixCodeApi);
        if (!slug) {
          return postService.getLastPost({ instance });
        }
        return isId ? postService.getPostById({ instance, id: slug }) : postService.getPostBySlug({ instance, slug });
      },
    }),
  };
};

export default postPageController;
